<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" md="12" class="py-0">
                <RegistryHeader
                    :title="getModelsRegistry.name"
                    :avatarData="getModelsRegistry.currentMenager"
                    @clearSearch="clearTable"
                />
                <Table
                :title="getModelsRegistry.name"
                    :items="getModelsTable.items"
                    :headers="headers"
                    :length="getModelsTable.pages"
                    :page="getModelsTable.page"
                    @search="setSearch"
                    @clear-table="clearTable"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @sort="setSort"
                    :defaultFields="getModelsSearchFields.searchFields"
                    :loading="getModelsTable.loading"
                >
                    <Columns slot="columns" :items="getModelsTable.items" />
                </Table>
                <Modal
                    :title="$t('models:additionModel')"
                    :open="open"
                    :height="'520'"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        :action="'create'"
                        slot="buttons"
                        @closeModal="open = false"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                small
                                v-if="add()"
                                @click="addModel()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('models:addModels') }}</span>
                    </v-tooltip>
                    <span></span>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import store from './../../../store/index'
import Columns from './../../../components/Registries/Models/Table/Columns'
import AEContent from './../../../components/Registries/Models/Modal/Content'
import Buttons from './../../../components/Registries/Models/Modal/Buttons'
export default {
    data() {
        return {
            open: false,
            headers: [
                {
                    text: this.$t('models:manufacturer'),
                    value: 'manufacturer',
                    width: '20%',
                    showSearch: true,
                },
                {
                    text: this.$t('models:model'),
                    value: 'model',
                    width: '20%',
                    showSearch: true,
                },
                {
                    text: this.$t('models:deviceType'),
                    value: 'deviceType',
                    width: '35%',
                    showSearch: true,
                },
                {
                    text: this.$t('models:servicePrice'),
                    value: 'servicePrice.name',
                    width: '15%',
                    showSearch: true,
                },
                {
                    text: this.$t('models:status'),
                    value: 'status.name',
                    width: '100px',
                    showSearch: true,
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '150px',
                    sortable: false,
                },
            ],
        }
    },
    components: {
        Columns,
        AEContent,
        Buttons,
    },
    computed: {
        ...mapGetters([
            'getModelsTable',
            'getModelsSearchFields',
            'getModelsRegistry',
            'getSearch',
        ]),
    },
    methods: {
        ...mapActions(['fetchModelsTable', 'fetchServicePricesItems']),
        ...mapMutations([
            'clearModelsModal',
            'clearModelErrors',
            'setModelsTable',
            'setModelsSearch',
            'clearModelsTable',
        ]),
        closeModal() {
            // this.clearModelsErrors()
            this.open = false
        },
        add() {
            return this.checkPermissions('CREATE')
        },
        async addModel() {
            await void this.fetchServicePricesItems({
                select: 'name ',
                where: { isDeleted: false },
            })
            this.open = true
            this.clearModelErrors()
            this.clearModelsModal()
        },
        setSearchFields(searchFields) {
            this.setModelsSearch(searchFields)
        },
        setSearch(search) {
            this.setModelsTable({ search, page: 1 })
            this.fetchModelsTable()
        },
        setPage(page) {
            this.setModelsTable({ page })
            this.fetchModelsTable()
        },
        setSort(sort) {
            this.setModelsTable({ sort })
            this.fetchModelsTable()
        },
        clearTable() {
            this.getSearch.text = ''
            this.clearModelsTable()
            this.fetchModelsTable()
        },
    },
    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/models/:id')
            store.commit('clearModelsTable')
        store.dispatch('fetchModelsTable').then(() => next())
    },
}
</script>
